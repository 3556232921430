@import "@/scss/_variables.scss";












































































.breakouts {
  .grid {
    @media (max-width: $width-tablet) {
      grid-template-columns: 1fr;
      grid-row-gap: 24px;
    }
  }

  hr {
    width: 100%;
    height: 1px;
    margin: 24px 0 24px 0;

    background-color: #CAD3D9;
    border: none;
  }
}
